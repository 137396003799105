import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { SimpleTour } from 'src/app/helper/simple-tour';
import { PageTourStep } from 'src/app/model/page-tour-step.model';
import { PageTour } from 'src/app/model/page-tour.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageTourService implements OnDestroy {

  tour = new ReplaySubject<PageTour | null>(1);
  private simpleTour?: SimpleTour;
  private subscription?: Subscription;
  private _tourKey!: string;
  private modalTourWrapper!: HTMLElement | null;

  constructor(private http: HttpClient) {
    this.modalTourWrapper = document.getElementById('modal-tour-wrapper');
  }

  setTour(tourKey: string, isModalTour?: boolean): void {
    if (this._tourKey === tourKey) return;
    const modalClosed = this._tourKey?.startsWith(tourKey);
    this._tourKey = tourKey;

    if (tourKey) {
      const encodedPageRefCd = encodeURIComponent(tourKey);
      this.http.get<PageTour | null>(environment.apiUrl + 'tour/' + encodedPageRefCd).subscribe(tour => {
        if (!tour) {
          this.tour.next(null);
          return;
        }

        tour.isModalTour = isModalTour;
        this.tour.next(tour.steps.length ? tour : null);
        if (!modalClosed && !tour.viewedFlag && tour.pushFlag) {
          this.showTour(tour);
        }
      });
      return;
    }
    this.tour.next(null);
  }

  showTour(pageTour: PageTour | null | undefined): void {
    if (!pageTour || pageTour?.pageTourStatCd !== 'A') return;

    let retriesLeft = 10;
    let availableSteps: PageTourStep[] = this.getAvailableSteps(pageTour);
    const interval = setInterval(() => {
      retriesLeft--;
      availableSteps = this.getAvailableSteps(pageTour);

      if (retriesLeft <= 0) {
        clearInterval(interval);
      }
      if (availableSteps.length > 0) {
        clearInterval(interval);

        const tourWrapperEl = pageTour.isModalTour && this.modalTourWrapper ? this.modalTourWrapper : undefined;
        this.simpleTour = new SimpleTour({
          modalContainer: tourWrapperEl,
          stepsContainer: tourWrapperEl
        }, pageTour);
        this.subscription = this.simpleTour.onTourCompleted.subscribe(() => {
          if (pageTour.viewedFlag) return;
          this.saveTourView(pageTour.pageRefCd);
        });
        this.simpleTour.tour?.start();
      }
    }, 500);
  }

  private getAvailableSteps(pageTour: PageTour): PageTourStep[] {
    return pageTour.steps.filter((step) => {
      if (!step.htmlElemId) return true;
      return document.querySelectorAll(step.htmlElemId).length > 0;
    });
  }

  private saveTourView(tourKey: string): void {
    const encodedPageRefCd = encodeURIComponent(tourKey);
    this.http.post(environment.apiUrl + 'tour/setViewed/' + encodedPageRefCd, null).subscribe();
  }

  ngOnDestroy(): void {
    this.simpleTour?.tour?.cancel();
    this.subscription?.unsubscribe();
  }

}
