import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { EMPTY, map, switchMap } from 'rxjs';
import { PageTourService } from 'src/app/service/page-tour.service';
import { RouterEventsService } from 'src/app/service/router-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  title = 'DocketScope';
  private pageRefCd: string | null = null;

  constructor(
    private routerEventsService: RouterEventsService,
    private pageTourService: PageTourService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.routerEventsService.routerEvents.pipe(
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route?.data || EMPTY),
    ).subscribe(({title: pageTitle, pageRefCd}) => {
      this.pageRefCd = pageRefCd;

      this.titleService.setTitle(pageTitle ? this.title + ' | ' + pageTitle : this.title);
      this.pageTourService.setTour(pageRefCd);
      this.routerEventsService.pageRefCd.next(pageRefCd);
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (this.pageRefCd && event?.target) {
      let el = event.target as HTMLElement;

      // if the icon in an icon button is clicked
      let i = 0;
      while (i < 2 && !el.classList.contains('has-tour') && el.parentElement) {
        el = el.parentElement;
      }

      if (el.classList.contains('has-tour')) {
        const subKey = el.hasAttribute('data-tour-subkey') ? '#' + el.getAttribute('data-tour-subkey') : '';
        const tourKey = this.pageRefCd + subKey;
        this.pageTourService.setTour(tourKey, el.classList.contains('has-modal-tour'));
      }
    }
  }
}
